import React, { useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from '../../authContext/authContext';

function ProtectedRoute({ children, isLoggedIn }) {
  const location = useLocation();

  return isLoggedIn ? children : <Navigate to="/login" state={{ from: location }} />;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default ProtectedRoute;
