import axios from 'axios';

export const serverURL = 'https://staging.api.hire360.ai';
// export const serverURL = ' https://discrete-pig-vaguely.ngrok-free.app';
// export const serverURL = 'http://localhost:3026';
// export const serverURL = 'https://e518-125-22-183-42.ngrok-free.app';

export const getAuthHeaders = () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    'ngrok-skip-browser-warning': true,
  };
  return { headers };
};

const APICall = (url, data = {}, method = 'get', authorization = true) => {
  return new Promise((resolve, reject) => {
    const finalURL = `${serverURL}${url}`;
    const headers = authorization ? getAuthHeaders() : {};
    let request;

    switch (method.toLowerCase()) {
      case 'get':
        request = axios.get(finalURL, headers);
        break;
      case 'post':
        request = axios.post(finalURL, data, headers);
        break;
      case 'patch':
        request = axios.patch(finalURL, data, headers);
        break;
      case 'delete':
        request = axios.delete(finalURL, headers);
        break;
      case 'put':
        request = axios.put(finalURL, data, headers);
        break;
      default:
        reject(new Error(`Invalid method: ${method}`));
        return;
    }

    request
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.toString().includes('status code 401') && localStorage.accessToken) {
          // logoutUser().then(() => {
          //   window.location.href = "/./";
          // });
        }
        reject(error);
      });
  });
};
export default APICall;
