import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const AddLeads = lazy(() => import('../../container/leadManagements/addLeads'));
const ScheduleCall = lazy(() => import('../../container/leadManagements/scheduleCalls'));
const LeadsView = lazy(() => import('../../container/leadManagements/viewLeads'));
const LeadsDetails = lazy(() => import('../../container/leadManagements/viewDetails'));
const NotFound = lazy(() => import('../../container/pages/404'));

function LeadsRoutes() {
  return (
    <Routes>
      <Route path="add" element={<AddLeads />} />
      <Route path="schedule" element={<ScheduleCall />} />
      <Route path="view" element={<LeadsView />} />
      <Route path="details" element={<LeadsDetails />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default LeadsRoutes;
