import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Message from './Message';
import Notification from './Notification';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { AuthContext } from '../../../authContext/authContext';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import APICall from '../../../utility/Api';

const AuthInfo = React.memo(() => {
  const { authUser, setIsLoggedIn, setAuthUser } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [employeeData, setEmployeeData] = React.useState();

  const getEmployeeData = async () => {
    try {
      setLoading(true);
      const response = await APICall(`/user`);
      if (response.data.documents && response.data.documents.length > 0) {
        const lastUser = response.data.documents[response.data.documents.length - 1];
        setEmployeeData(lastUser);
        console.log('Employee Data:', lastUser);
      } else {
        console.log('No employee Data Found');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  const SignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('authUser');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('accessToken');
    setAuthUser(null);
    setIsLoggedIn(false);
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            height={50}
            width={50}
            src={
              employeeData?.userDetails?.profilePhoto
                ? employeeData.userDetails.profilePhoto
                : require('../../../static/img/avatar/chat-auth.png')
            }
            alt=""
          />
          <figcaption>
            <Heading as="h5">{authUser ? authUser.name : 'Guest'}</Heading>
            <p style={{ textTransform: 'capitalize' }}>{authUser ? authUser.role : 'Guest'}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/admin/profile">
              <UilUser /> Profile
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Message />
      <Notification />
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar
              src={
                employeeData?.userDetails?.profilePhoto
                  ? employeeData.userDetails.profilePhoto
                  : 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'
              }
            />
            <span className="ninjadash-nav-actions__author--name">{authUser ? authUser.name : 'Guest'}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
