import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy, useContext } from 'react';
import { Provider, useSelector } from 'react-redux';

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store from './redux/store';

import Admin from './routes/admin';
import Auth from './routes/auth';
import Sales from './routes/sales';
import './static/css/style.css';
import { AuthContext } from './authContext/authContext';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { rtl, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
    };
  });

  const { isLoggedIn, authUser } = useContext(AuthContext);
  const [path, setPath] = useState(window.location.pathname);

  // const isLoggedIn = true;

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => {
      unmounted = true;
    };
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/login/*" element={<Auth />} />
            <Route
              path="/admin/*"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn && authUser?.role === 'admin'}>
                  <Admin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sales/*"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn && authUser?.role === 'sales'}>
                  <Sales />
                </ProtectedRoute>
              }
            />
            {/* <Route path="*" element={<NotFound />} /> */}
            <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
