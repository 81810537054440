import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// Create the AuthContext
export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(localStorage.getItem('isLoggedIn')) || false);
  const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem('authUser')) || null);

  const authContextValue = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
      authUser,
      setAuthUser,
    }),
    [isLoggedIn, authUser],
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
